import axios from 'axios';
import dataTemplate from '/app/javascript/shared/json/dataAccountZalo.json';

const state = {
  accountTemp: [],
  accountDropdown: [
    {
      id: 0,
      name: '',
      phoneNumber: 0,
      display_name: '',
      zalo_information: '',
    },
  ],
  accounts: [
    {
      Info: {
        AccountName: '',
        NumberPhone: '',
        URL_Avatar: '',
        username: '',
        URL_Cover: '',
        DoB: '',
        Gender: '',
      },
    },
  ],
  error: null,
  isLoading: false,
  uiFlags: {
    isFetching: false,

    isRemoving: false,
  },
  NotificationData: {
    message: '',
    data: {
      AccountName: '',
      NumberPhone: '',
      URL_Avatar: '',
      username: '',
      URL_Cover: '',
      DoB: '',
      Gender: '',
    },
  },
};

const getters = {
  account: state1 => state1.account,
  accounts: state2 => state2.accounts,
  isLoading: state3 => state3.uiFlags.isFetching,
  fetchRemoving: state4 => state4.uiFlags.isRemoving,
  error: state5 => state5.error,
  NotificationData: state6 => state6.NotificationData,
  AccountDropdown: state7 => state7.accountDropdown,
};
const headers = {
  Authorization: '123456',
};
const headers2 = {
  Authorization: '5wKt7Cq3okmneuRemtKj8dhp',
};
const HOST_URL = process.env.VUE_APP_API_CUSTOM;
const actions = {
  filterAccount({ commit }, search) {
    if (!search.trim()) commit('SET_ACCOUNTS', state.accountTemp);
    const searchInput = search.toLowerCase();
    const accounts = state.accounts.filter(item => {
      const normalizedPhone = item.Info.NumberPhone.replace(/^\+84/, '0');
      return (
        item.Info.AccountName.toLowerCase().includes(searchInput) ||
        normalizedPhone.includes(searchInput)
      );
    });
    if (accounts && accounts.length) {
      commit('SET_ACCOUNTS', accounts);
    } else {
      commit('SET_ACCOUNTS', state.accountTemp);
    }
  },
  async fetchAccounts({ commit }) {
    console.log(HOST_URL);
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      // const response = await axios.get(`${HOST_URL}/get_routes`, {
      //   headers: headers,
      // });
      // let account_list = response.data;

      // const promises = account_list.map(async item => {
      //   const response2 = await axios.get(
      //     `http://${item[1]}.cloudcenter.vn/information`,
      //     {
      //       headers: headers2,
      //     }
      //   );
      //   const data = response2.data.profile;
      //   item.Info = {
      //     AccountName: data.zaloName ?? '',
      //     NumberPhone: data.phoneNumber ?? '',
      //     URL_Avatar: data.avatar ?? '',
      //     username: data.username ?? '',
      //     URL_Cover: data.cover ?? '',
      //     Gender: data.Gender !== 0 ? 'Nam' : 'Nữ',
      //     DoB: data.sdob ?? '',
      //   };
      //   const normalizedPhone = item.Info.NumberPhone.replace(/^\+84/, '0');
      //   item.display_name = data.zaloName + ' ( ' + normalizedPhone + ' )';
      //   return item;
      // });

      // account_list = await Promise.all(promises);

      commit('SET_ACCOUNTS', dataTemplate);
      commit('SET_ACCOUNTSTEMP', dataTemplate);
    } catch (error) {
      commit('SET_ERROR', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchAccountsToDropdown({ commit }) {
    console.log(HOST_URL);
    try {
      const response = await axios.get(`${HOST_URL}/get_routes`, {
        headers: headers,
      });
      let account_list = response.data;
      const promises = account_list.map(async item => {
        const response2 = await axios.get(
          `http://${item[1]}.cloudcenter.vn/information`,
          {
            headers: headers2,
          }
        );

        const data = response2.data.profile;

        const normalizedPhone = data.phoneNumber.replace(/^\+84/, '0');
        const account = {
          id: item[0],
          name: data.zaloName,
          phoneNumber: normalizedPhone,
          display_name: `${data.zaloName} (${normalizedPhone})`,
          zalo_information: `${data.zaloName}-${data.phoneNumber}`,
        };
        return account;
      });
      const result = await Promise.all(promises);
      commit('SET_ACCOUNTDROPDOWN', result);
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },
  async getAccountById({ commit }, selectedAccountID) {
    try {
      commit('SET_LOADING', true);
      commit('SET_ERROR', null);
      const response = await axios.get(
        `http://${selectedAccountID}.cloudcenter.vn/information`,
        {
          headers: headers2,
        }
      );
      commit('SET_ACCOUNT', response.data);
    } catch (error) {
      commit('SET_ERROR', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async removeAccount({ commit }, account) {
    const accountId = account[1];

    try {
      // Access the second element of the account array
      const response = await axios.delete(
        `${HOST_URL}/remove_route/${accountId}`,
        {
          headers: headers,
        }
      );
      if (response.data.message !== 'Route removed successfully') {
        commit('SET_ERROR', response.data.message);
      } else {
        commit('REMOVE_ACCOUNT', accountId);
      }
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  async checkInformationUser({ commit }, account) {
    const response = await axios.get(`http://${account[2]}/information`, {
      headers: '5wKt7Cq3okmneuRemtKj8dhp',
    });
    commit('SET_ACCOUNTS', response.data);
  },
};

const mutations = {
  SET_ACCOUNT(stateAccount, account) {
    const data = account.profile;
    stateAccount.NotificationData = {
      message: 'Thành Công',
      data: {
        AccountName: data.zaloName,
        NumberPhone: data.phoneNumber,
        URL_Avatar: data.avatar,
        username: data.username,
        URL_Cover: data.cover,
        Gender: data.Gender !== 0 ? 'Nam' : 'Nữ',
        DoB: data.sdob,
      },
    };
  },
  SET_ACCOUNTDROPDOWN(stateAccountDropdown, accounts) {
    stateAccountDropdown.accountDropdown = accounts;
  },
  SET_ACCOUNTS(stateAccounts, accounts) {
    stateAccounts.accounts = accounts;
  },
  SET_ACCOUNTSTEMP(stateAccountTemp, accounts) {
    stateAccountTemp.accountTemp = accounts;
  },
  SET_LOADING(stateloading, loading) {
    stateloading.isLoading = loading;
  },
  SET_ERROR(stateError, error) {
    stateError.NotificationData = { message: error, data: {} };
  },
  REMOVE_ACCOUNT(stateRemove, accountId) {
    stateRemove.accounts = stateRemove.accounts.filter(
      account => account[1] !== accountId
    );
    stateRemove.NotificationData = {
      message: 'Thành Công',
      data: {
        AccountName: '',
        NumberPhone: '',
        URL_Avatar: '',
        username: '',
        URL_Cover: '',
        Gender: '',
        DoB: '',
      },
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
