/* global axios */
import ApiClient from '../ApiClient';

class ZaloChannel extends ApiClient {
  constructor() {
    super('facebook_indicators', { accountScoped: true });
  }

  create(params) {
    return axios.post(
      `${this.url.replace(this.resource, '')}callbacks/register_zalo_page`,
      params
    );
  }
}

export default new ZaloChannel();
