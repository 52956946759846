import axios from 'axios';
import Swal from 'sweetalert2';

const state = {
  isFriend: false,
  zalo_accounts: [
    {
      userId: 0,
      name: '',
      phoneNumber: 0,
    },
  ],
  accounts: [
    {
      avatar: '',
      displayName: '',
      gender: '',
      username: '',
      phoneNumber: '',
    },
  ],
  iframeLoading: true,
  result: {
    uid: '',
    avatar: '',
    display_name: '',
    gender: '',
    username: '',
    phoneNumber: '',
  },
  message: '',
};

const getters = {
  accounts: state2 => state2.accounts,
  result: state1 => state1.result,
  iframeLoading: state3 => state3.iframeLoading,
  message: state4 => state4.message,
  zalo_accounts: state5 => state5.zalo_accounts,
  isFriend: state6 => state6.isFriend,
};

const headers = {
  Authorization: '5wKt7Cq3okmneuRemtKj8dhp',
};
const headers2 = {
  Authorization: '123456',
};
const HOST_URL = process.env.VUE_APP_API_CUSTOM;

const actions = {
  async fetchAccounts({ commit }) {
    try {
      const response = await axios.get(`${HOST_URL}/get_routes`, {
        headers: headers2,
      });
      let account_list = response.data;
      const promises = account_list.map(async item => {
        const response2 = await axios.get(
          `http://${item[1]}.cloudcenter.vn/information`,
          {
            headers: headers,
          }
        );

        const data = response2.data.profile;

        const normalizedPhone = data.phoneNumber.replace(/^\+84/, '0');
        const account = {
          userId: data.userId,
          name: data.zaloName,
          phoneNumber: normalizedPhone,
        };
        return account;
      });
      const result = await Promise.all(promises);
      commit('SET_ZALO_OA', result);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  },

  async fetchFriendList({ commit }, phoneNumber) {
    try {
      commit('SET_LOADING', true);
      if (!phoneNumber) return;
      const normalizedPhone = phoneNumber.replace(/^\+84/, '0');
      const response = await axios.get(
        `http://account_${normalizedPhone}.cloudcenter.vn/user/friends`,
        {
          headers: headers,
        }
      );

      let account_list = response;
      commit('SET_ACCOUNTS', account_list.data);
      commit('SET_LOADING', false);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  },
  async findFriendByNumberPhone({ commit }, data) {
    try {
      commit('SET_LOADING', true);
      const response = await axios.get(
        `http://account_${data.myPhone}.cloudcenter.vn/user/informationPhone?phoneNumber=${data.phoneNumber}`,
        {
          headers: headers,
        }
      );

      let account = response;
      commit('SET_ACCOUNT', account.data);
      commit('SET_LOADING', false);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  },

  async makeFriend(_, data) {
    try {
      const response = await axios.get(
        `http://account_${data.myPhone}.cloudcenter.vn/user/addFriend?userId=${data.uid}`,
        {
          headers: headers,
        }
      );

      if (
        response.data.error_message &&
        response.data.error_message === 'Successful.'
      ) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Thông báo',
          text: 'Gửi yêu cầu kết bạn thành công',
          timer: 5000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'info',
          title: 'Thông báo',
          text: 'Yêu cầu kết bạn chưa thành công',
          timer: 5000,
          showConfirmButton: false,
        });
      }

      //  let account = response;
      //  commit('SET_ACCOUNT', account.data);
      //  console.log(response);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  },

  async checkFriend({ commit }, data) {
    try {
      let isFriend = false;
      let names = '';
      commit('SET_MSG', '');
      const promises = state.zalo_accounts.map(async account => {
        try {
          // eslint-disable-next-line
          const response = await axios.get(
            `http://account_${account.phoneNumber}.cloudcenter.vn/user/friends`,
            {
              headers: headers,
            }
          );
          // eslint-disable-next-line
          response.data.forEach(element => {
            if (element.phoneNumber) {
              const normalizedPhone = element.phoneNumber.replace(/^84/, '0');

              if (
                normalizedPhone === data.phoneNumber &&
                data.myPhone === account.phoneNumber
              ) {
                names += 'Bạn ; ';
                isFriend = true;
              } else if (normalizedPhone === data.phoneNumber) {
                names += account.name + ' ; ';
              }
            }
          });

          // If the friend is found, commit the mutation outside of the inner loop
          if (isFriend) {
            commit('SET_ISFRIEND', true);
          }
        } catch (error) {
          // eslint-disable-next-line
          console.log(
            `Error fetching friends for account ${account.phoneNumber}:`,
            error
          );
        }
      });

      await Promise.all(promises);

      commit(
        'SET_MSG',
        names
          ? `Đã là bạn bè với ${names.trim()}`
          : 'Chưa kết bạn với bất kỳ tài khoản Zalo_OA nào'
      );
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  },
};

const mutations = {
  SET_ZALO_OA(stateZaloAccount, accounts) {
    stateZaloAccount.zalo_accounts = accounts;
  },
  SET_MSG(stateMsg, msg) {
    stateMsg.message = msg;
  },
  SET_ISFRIEND(stateIsFriend, value) {
    stateIsFriend.isFriend = value;
  },
  SET_ACCOUNTS(stateAccounts, accounts) {
    stateAccounts.accounts = accounts.map(account => ({
      avatar: typeof account.avatar === 'string' ? account.avatar : '',
      displayName:
        typeof account.displayName === 'string' ? account.displayName : '',
      gender: account.gender === 0 ? 'Nam' : 'Nữ',
      username: typeof account.username === 'string' ? account.username : '',
      phoneNumber:
        typeof account.phoneNumber === 'string' ? account.phoneNumber : '',
    }));
  },
  SET_LOADING(stateLoading, value) {
    stateLoading.iframeLoading = value;
  },
  SET_ACCOUNT(stateAccount, account) {
    stateAccount.result = account;
    stateAccount.result.gender = account.gender === 0 ? 'Nam' : 'Nữ';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
