<template>
  <div class="container">
    <div class="content">
      <div v-if="notification">{{ notification }}</div>
      <LoadingState v-if="loading" />
      <div v-else-if="authStatus === 'success'">
        <div class="account-info">
          <div class="avatar-container">
            <img :src="userInfo.avatar" alt="Avatar" class="avatar" />
          </div>
          <div class="user-info">
            <div><strong>Tài khoản:</strong> {{ userInfo.username }}</div>
            <div><strong>SDT:</strong> {{ userInfo.phone }}</div>
          </div>
        </div>
        <button class="button" @click="acceptAccount">
          Đồng ý tạo tài khoản này
        </button>
      </div>
      <img
        v-if="qrCodeImageSrc && !loading"
        :src="qrCodeImageSrc"
        alt="QR Code"
        class="qrcode-image"
      />
      <button v-if="!loading" class="button" @click="callQrCode">
        Tạo mới mã QR
      </button>
    </div>
  </div>
</template>

<script>
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import { mapState } from 'vuex';

export default {
  components: {
    LoadingState,
  },
  beforeDestroy() {
    this.$store.dispatch('websocket_zalo/disconnect');
  },
  computed: {
    ...mapState('websocket_zalo', [
      'loading',
      'authStatus',
      'notification',
      'qrCodeImageSrc',
      'userInfo',
    ]),
  },
  mounted() {
    this.$store.dispatch('websocket_zalo/initSocket');

    setTimeout(() => {
      this.$store.dispatch('websocket_zalo/reviveDataFromWebsocket');
      this.$store.dispatch('websocket_zalo/initializeWebSocket');
      this.$store.dispatch('websocket_zalo/callWebSocket');
    }, 10);
  },
  methods: {
    callQrCode() {
      this.$store.dispatch('websocket_zalo/callWebSocket');
    },
    acceptAccount() {
      this.$store.dispatch('websocket_zalo/callAcceptAccount');
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Make the container full height of the viewport */
  width: 150vw; /* Make the container full width of the viewport */
  background-color: #f5f5f5; /* Optional: Set a background color */
  margin-top: -50px; /* Adjust this value to move the spinner higher */
  margin-bottom: 10px;
}

.content {
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.button {
  padding: 10px 20px;
  font-size: 18px;
  margin-top: 20px;
  background-color: #09f;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.button:hover {
  background-color: white;
  color: #09f;
  border: 2px solid #09f;
}

.qrcode-image {
  width: 300px;
  height: 300px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.account-info {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.account-info div {
  margin: 5px 0;
}
</style>
