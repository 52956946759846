const state = () => ({
  zaloAppId: process.env.VUE_APP_ZALO_APP_ID,
  zaloSecretKey: process.env.VUE_APP_ZALO_APP_SECRET,
  baseUrl: process.env.VUE_APP_ZALO_AUTHEN,
  baseUrlAuthor: process.env.VUE_APP_ZALO_AUTHOR,
  baseUrlOa: process.env.VUE_APP_ZALO_OA,
});

const getters = {
  getZaloAppId: state => state.zaloAppId,
  getZaloSecretKey: state => state.zaloSecretKey,
  getBaseUrl: state => state.baseUrl,
  getBaseUrlAuthor: state => state.baseUrlAuthor,
  getBaseUrlOa: state => state.baseUrlOa,
};

export default {
  namespaced: true,
  state,
  getters,
};
