import io from 'socket.io-client';
import Swal from 'sweetalert2';
import routes from '../../routes/index';

// Mutation and Action Types
const SET_LOADING = 'setLoading';
const SET_AUTH_STATUS = 'setAuthStatus';
const SET_NOTIFICATION = 'setNotification';
const SET_QR_CODE_IMAGE_SRC = 'setQrCodeImageSrc';
const SET_USER_INFO = 'setUserInfo';
const RESET_STATE = 'resetState';

const getDefaultState = () => ({
  loading: false,
  authStatus: null,
  notification: '',
  qrCodeImageSrc: '',
  userInfo: {
    username: '',
    phone: '',
    avatar: '',
  },
});

let socket = null;
const state = getDefaultState();

const mutations = {
  [SET_LOADING](stateLoaing, loading) {
    stateLoaing.loading = loading;
  },
  [SET_AUTH_STATUS](stateStatus, status) {
    stateStatus.authStatus = status;
  },
  [SET_NOTIFICATION](stateNotification, message) {
    stateNotification.notification = message;
  },
  [SET_QR_CODE_IMAGE_SRC](stateQr, src) {
    stateQr.qrCodeImageSrc = src;
  },
  [SET_USER_INFO](stateUserInfo, data) {
    stateUserInfo.userInfo = {
      username: data.username,
      phone: data.phone,
      avatar: data.avatar,
    };
  },
  [RESET_STATE](stateRoot) {
    Object.assign(stateRoot, getDefaultState());
  },
};

const actions = {
  initSocket() {
    // Replace with your backend URL
    socket = io('http://cloudcenter.vn:5000', {
      query: {
        Authorization: '5wKt7Cq3okmneuRemtKj8dhp',
      },
    });
  },
  callWebSocket({ commit }) {
    commit(RESET_STATE);
    commit(SET_LOADING, true);
    socket.emit('get_qr_code');
    commit(SET_NOTIFICATION, 'Vui lòng chờ để hệ thống tạo mã QR');
  },

  callAcceptAccount({ commit }) {
    socket.emit('set_account');
    commit(SET_LOADING, true);
    commit(SET_NOTIFICATION, 'Đang khởi tạo tài khoản');
  },

  initializeWebSocket({ commit }) {
    socket.on('connect', () => {
      commit(SET_LOADING, false);
      commit(SET_NOTIFICATION, 'Đã kết nối lại với Server');
    });

    socket.on('disconnect', () => {
      commit(RESET_STATE);
      commit(SET_NOTIFICATION, 'Đã bị mất kết nối với Server');
    });
  },

  reviveDataFromWebsocket({ commit }) {
    socket.on('qr_code', data => {
      commit(SET_LOADING, false);
      commit(SET_NOTIFICATION, 'Quét QR để xác thực User');
      commit(SET_QR_CODE_IMAGE_SRC, data.qr_code);
      socket.emit('handle_authentication');
    });

    socket.on('authentication_result', data => {
      commit(SET_LOADING, false);
      commit(SET_AUTH_STATUS, data.status ? 'success' : 'fail');
      commit(SET_QR_CODE_IMAGE_SRC, '');
      if (data.status) {
        commit(SET_USER_INFO, data.userInfo);
        commit(SET_NOTIFICATION, 'Xác thực User thành công');
      } else {
        commit(SET_NOTIFICATION, 'Xác thực User thất bại');
      }
    });

    socket.on('Status', data => {
      commit(SET_LOADING, false);
      if (data.status) {
        commit(SET_QR_CODE_IMAGE_SRC, '');
        Swal.fire({
          icon: 'success',
          title: 'Thông báo',
          text: data.message,
          timer: 5000,
          showConfirmButton: false,
        }).then(() => {
          routes.push({ name: 'settings_inbox_list' });
        });
        commit(RESET_STATE);
      } else {
        commit(RESET_STATE);
        commit(SET_NOTIFICATION, data.message);
      }
    });
  },
  disconnect() {
    if (socket) {
      socket.disconnect();
      socket = null;

      // eslint-disable-next-line
      console.log('Socket disconnected successfully');
    } else {
      socket.disconnect();
      socket = null;

      // eslint-disable-next-line
      console.log('Socket reset successfully');
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
